import { Button, Divider, Grid } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../../components/ui/input";
import PrimaryButton from "../../../components/ui/primaryButton";

function ClubAdd() {
  const { control, register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      await axios
        .post("/api/event", data)
        .then((response) => console.log(response))
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h2>Add new club</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input {...register("title")} control={control} label="Title" />
          </Grid>
          <Grid item xs={12}>
            <Input
              control={control}
              {...register("club-description")}
              label="Club description"
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              control={control}
              {...register("club-description")}
              label="Location"
            />
          </Grid>

          <Divider sx={{ height: "50px" }} />

          <Grid item xs={6}>
            <PrimaryButton type="submit">Submit</PrimaryButton>
            <Button href="/admin/club-list">Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default ClubAdd;
