import React from "react";
import { Container, TextField, Divider } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../slices/auth.slice"; // Replace with your actual login action
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(loginUser(data))
      .unwrap()
      .then(() => {
        navigate("/admin/user-list");
      })
      .catch((error) => {
        // Handle login failure, e.g., show an error message
        console.error("Login failed:", error);
      });
  };

  return (
    <Container className="darkBkContainer">
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://s3.af-south-1.amazonaws.com/s3.charlotte.public/Transparent+on+Black.png"
            }
            width={"250px"}
            style={{ alignSelf: "center" }}
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <TextField
            {...register("email")}
            className="lightOutlineInput"
            fullWidth
            hiddenLabel
            placeholder="email"
            variant="filled"
            size="small"
            margin="dense"
            helperText={errors.email && errors.email?.message}
            error={!!errors.email}
          />

          <TextField
            {...register("password")}
            className="lightOutlineInput"
            fullWidth
            hiddenLabel
            placeholder="Password"
            variant="filled"
            size="small"
            type="password"
            margin="dense"
            helperText={errors.password && errors.password?.message}
            error={!!errors.password}
          />

          <div style={{ margin: "10px 0" }}>
            <button type="submit" className="btn whiteBtn">
              Login
            </button>
          </div>
        </form>

        <div style={{ margin: "50px 0" }}>
          <Link
            className="whiteTxt"
            to="/SignUp"
            style={{ textAlign: "center", display: "block", marginTop: "20px" }}
          >
            Don't have an account?
          </Link>

          <Link
            className="whiteTxt"
            to="/privacy-policy"
            style={{ textAlign: "center", display: "block", marginTop: "10px" }}
          >
            Privacy Policy
          </Link>
        </div>
      </Container>
    </Container>
  );
}

export default Login;
