import { Divider, Grid } from "@mui/material";
import axios from "axios";
import * as React from "react";
import { useForm } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import Input from "../../../components/ui/input";

function EventAdd() {
  const { control, register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    try {
      await axios
        .post("/api/event/", data)
        .then((response) => console.log(response))
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h2>PBA - March</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <p>29 March - 1 April 2024</p>
            <p>Dubrnaville BC</p>
            <p>Members only enty</p>
            <p>
              <b>Format:</b>
            </p>
            <p>Singles & Two Bowl Pairs</p>
            <p>Six games per player</p>
          </Grid>

          <Grid item xs={6}>
            <Input
              {...register("socialLink")}
              id="socialLink"
              control={control}
              label="Social media link"
            />
            <label for="mediaFile">Media photos</label>
            <input {...register("mediaFile")} type="file" id="mediaFile" />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <h2>Files</h2>
          </Grid>
          <Grid item xs={6}>
            <label for="copFile">Conditions of Play</label>
            <input {...register("copFile")} type="file" id="copFile" />
          </Grid>

          <Grid item xs={6}>
            <label for="playingScheduleFile">Playing Schedule</label>
            <input
              {...register("playingScheduleFile")}
              type="file"
              id="playingScheduleFile"
            />
          </Grid>
          <Grid item xs={6}>
            <label for="resultFile">Results</label>
            <input {...register("resultFile")} type="file" id="resultFile" />
          </Grid>
          <Grid item xs={6}>
            <label for="drawFile">Draws</label>
            <input {...register("drawFile")} type="file" id="drawFile" />
          </Grid>

          <Divider sx={{ height: "50px" }} />
          <Grid item xs={6}>
            <input className="btn btn-accent" type="submit" value="Update" />
          </Grid>
          <Grid item xs={6}>
            <a className="btn btn-accent-outline" href="/admin/events-list">
              Cancel
            </a>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default EventAdd;
