import { config } from "../features/config";

import axios from "axios";

export const authService = {
  login,
  register,
};

function login(credentials) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(`${config.auth_server}/login`, credentials, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function register(credentials) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(`${config.auth_server}/register`, credentials, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
