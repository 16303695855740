import { Grid, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/ui/primaryButton";
import { getEventsAsync } from "../../../slices/event.slice";

function EventsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsWithId, setEventsWithId] = useState([]);

  useEffect(() => {
    try {
      dispatch(getEventsAsync())
        .unwrap()
        .then((res) => {
          const newEvents = res.map((event) => ({ ...event, id: event._id }));
          setEventsWithId(newEvents);
        })
        .catch((error) => {
          console.error("error failed:", error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleEdit = () => {
    const path = "../admin/event-add";
    navigate(path, { state: { id: selectedEvent.id } });
  };
  const handleSections = () => {
    const path = "../admin/event-section";
    navigate(path, { state: { event: selectedEvent } });
  };

  const columns = [
    { field: "title", headerName: "Title", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "location", headerName: "Location", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    { field: "startDate", headerName: "Date", width: 150 },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <h1>Events</h1>

      <Grid item xs={12}>
        <PrimaryButton href="/admin/event-add">Add new event</PrimaryButton>
        <PrimaryButton variant="outlined" href="/admin/pba-event">
          PBA Event
        </PrimaryButton>
      </Grid>

      <Paper>
        {selectedEvent && (
          <Grid item xs={12} display="flex" justifyContent="end">
            <PrimaryButton onClick={() => handleEdit()}>
              Edit Event
            </PrimaryButton>
            <PrimaryButton variant="outlined" onClick={() => handleSections()}>
              Edit Even Sections
            </PrimaryButton>
          </Grid>
        )}{" "}
        <DataGrid
          rows={eventsWithId}
          columns={columns}
          editMode="row"
          onRowClick={(data, e) => {
            setSelectedEvent(data.row);
          }}
        />
      </Paper>
    </div>
  );
}

export default EventsList;
