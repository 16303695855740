import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/ui/primaryButton";

const columns = [
  { field: "name", headerName: "Name", width: 200 },
  { field: "location", headerName: "Location", width: 200 },
  { field: "status", headerName: "Status", width: 150 },
];

const rows = [
  {
    id: 1,
    name: "Durbanville Bowls",
    location: "8th Street",
    status: "Active",
  },
  { id: 2, title: "Everstdal", location: "36 strand st.", status: "in-Active" },
];

function ClubList() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api/club")
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  const navigate = useNavigate();
  return (
    <div style={{ height: 400, width: "100%" }}>
      <h1>Clubs</h1>
      <PrimaryButton href="/admin/club-add">Add new club</PrimaryButton>

      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        onRowClick={(data, e) => {
          console.log(data.id);
          console.log(data.row.title);
          const path = "../club-view";
          navigate(path, {
            state: {
              id: data.id,
              name: data.row.name,
              location: data.row.location,
              date: data.row.date,
            },
          });
        }}
      />
    </div>
  );
}

export default ClubList;
