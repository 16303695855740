import { Container, TextField, Button } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../slices/auth.slice";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().required("email is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // Dispatch the registerUser action with the form data
    dispatch(registerUser(data))
      .unwrap()
      .then(() => {
        navigate("/admin/user-list");
      });
  };

  return (
    <Container className="darkBkContainer">
      <Container>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={
              "https://s3.af-south-1.amazonaws.com/s3.charlotte.public/Transparent+on+Black.png"
            }
            width={"250px"}
            style={{ alignSelf: "center" }}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("firstName")}
            className="lightOutlineInput"
            fullWidth
            hiddenLabel
            placeholder="First Name"
            variant="filled"
            size="small"
            margin="dense"
            helperText={errors.firstName && errors.firstName?.message}
            error={!!errors.firstName}
          />

          <TextField
            {...register("lastName")}
            className="lightOutlineInput"
            fullWidth
            hiddenLabel
            placeholder="Last Name"
            variant="filled"
            size="small"
            margin="dense"
            helperText={errors.lastName && errors.lastName?.message}
            error={!!errors.lastName}
          />
          <TextField
            {...register("email")}
            className="lightOutlineInput"
            fullWidth
            hiddenLabel
            placeholder="email"
            variant="filled"
            size="small"
            margin="dense"
            helperText={errors.email && errors.email?.message}
            error={!!errors.email}
          />

          <TextField
            {...register("password")}
            className="lightOutlineInput"
            fullWidth
            hiddenLabel
            placeholder="Password"
            variant="filled"
            size="small"
            margin="dense"
            helperText={errors.password && errors.password?.message}
            error={!!errors.password}
          />

          <TextField
            {...register("confirmPassword")}
            className="lightOutlineInput"
            fullWidth
            hiddenLabel
            placeholder="Confirm Password"
            variant="filled"
            size="small"
            margin="dense"
            helperText={
              errors.confirmPassword && errors.confirmPassword?.message
            }
            error={!!errors.confirmPassword}
          />

          <div style={{ margin: "10px 0" }}>
            <button type="submit" className="btn whiteBtn">
              Sign up
            </button>
          </div>
        </form>
        {/* <div style={{ margin: "10px 0" }}>
          <Link to="/admin/news-list" className="btn whiteBtn">
            Sign up
          </Link>
        </div> */}
        <div style={{ margin: "50px 0" }}>
          <Link
            className="whiteTxt"
            to="/Login"
            style={{ textAlign: "center", display: "block", marginTop: "20px" }}
          >
            Already have an account?
          </Link>
          <Link
            className="whiteTxt"
            to="/privacy-policy"
            style={{ textAlign: "center", display: "block", marginTop: "10px" }}
          >
            Privacy Policy
          </Link>
        </div>
      </Container>
    </Container>
  );
}

export default SignUp;
