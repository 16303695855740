import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

function UserView(props) {

    const location = useLocation();

    return (
        <div>
            <Link to={'../user-list'} className='breadCrumb' >{'<'}back</Link>
            <h1>Edit user</h1>

            <TextField
                label="User name"
                defaultValue={location.state.name} />
            <FormGroup>
                <FormControlLabel control={<Checkbox />} label='Is Active' />
            </FormGroup>

            {/* add user roles */}
        </div>
    )
}

export default UserView