import axios from "axios";
import { config } from "../features/config";

const addEvent = async (credentials) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(`${config.event_server}`, credentials, requestOptions)
    .then((response) => {
      return response.data;
    });
};

const getEvents = async (credentials) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .get(`${config.event_server}`, credentials, requestOptions)
    .then((response) => {
      return response.data;
    });
};

const getEventUsers = async (eventId) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .get(`${config.user_server}/byEvent/${eventId}`, eventId, requestOptions)
    .then((response) => {
      return response.data;
    });
};

export const eventService = {
  addEvent,
  getEvents,
  getEventUsers,
};
