import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Input from "../../../components/ui/input";
import { getEventUsersAsync } from "../../../slices/event.slice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Box, Grid, Typography } from "@mui/material";
import { setAlertInfo } from "../../../slices/alert.slice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EventSections() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yup.object().shape({})),
  });

  const dispatch = useDispatch();
  const location = useLocation();

  const eventUsers = useSelector((state) =>
    state.events.eventUsers.map((event) => event)
  );

  const event = location.state ? location.state.event : null;
  const eventId = event ? event.id : null;

  const eventCategory = event ? event.category : null;
  const maxSelection =
    eventCategory === "singles"
      ? 1
      : eventCategory === "doubles"
      ? 2
      : eventCategory === "triples"
      ? 3
      : eventCategory === "fours"
      ? 4
      : eventCategory === "fives"
      ? 5
      : null;

  useEffect(() => {
    if (eventId) {
      dispatch(getEventUsersAsync(eventId));
    }
  }, [dispatch, eventId]);

  const [numSections, setNumSections] = useState(null);
  const [numPlayerGroups, setNumPlayerGroups] = useState(null);
  const [renderUsersState, setRenderUsersState] = useState([]);

  const handleNumSectionsChange = (event) => {
    const value = parseInt(event.target.value);
    setNumSections(value);
  };

  const handleNumPlayerGroupsChange = (event) => {
    const value = parseInt(event.target.value);
    setNumPlayerGroups(value);
  };

  useEffect(() => {
    if (numSections) {
      const initialState = Array(numSections).fill([]);
      setRenderUsersState(initialState);
    }
  }, [numSections]);

  const renderUsers = (sectionIndex, playerIndex) => {
    const renderUsersStateRef = renderUsersState[sectionIndex] || [];
    const selectedValues = renderUsersStateRef[playerIndex] || [];

    console.log("selectedValues", selectedValues);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      if (value.length <= maxSelection) {
        const updatedState = [...renderUsersState];
        updatedState[sectionIndex] = [...(renderUsersStateRef || [])];
        updatedState[sectionIndex][playerIndex] = value;
        setRenderUsersState(updatedState);
      } else {
        dispatch(setAlertInfo(`Max selection for ${eventCategory} `));
      }
    };

    return (
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id={`multiple-chip-label-${sectionIndex}-${playerIndex}`}>
          Users
        </InputLabel>
        <Select
          labelId={`multiple-chip-label-${sectionIndex}-${playerIndex}`}
          id={`multiple-chip-${sectionIndex}-${playerIndex}`}
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {eventUsers.map((user, index) => {
            const userIndexInState = renderUsersState.indexOf(user._id);
            const userExistsInState = userIndexInState !== -1;
            console.log(`${user._id}, ${renderUsersState}`);

            return (
              <MenuItem
                key={index}
                value={user._id}
                disabled={userExistsInState}
              >
                {`${user.firstName} ${user.lastName}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      {event && (
        <>
          <Grid item md={12}>
            <Typography sx={{ mb: 2 }} variant="h2">
              {`${event.title} (${event.category})`}
            </Typography>
          </Grid>

          <Grid item md={6}>
            <Input
              type="number"
              label="Number Of Sections"
              value={numSections ? numSections : ""}
              onChange={handleNumSectionsChange}
            />
          </Grid>
          <Grid item md={6}>
            <Input
              value={numPlayerGroups ? numPlayerGroups : ""}
              onChange={handleNumPlayerGroupsChange}
              type="number"
              label="Number Of Players Groups per Section"
            />
          </Grid>

          {numSections &&
            [...Array(numSections)].map((_, sectionIndex) => (
              <Grid key={sectionIndex} item md={2}>
                <Typography variant="h5">{`Section ${
                  sectionIndex + 1
                }`}</Typography>
                {numPlayerGroups &&
                  [...Array(numPlayerGroups)].map((_, playerIndex) => (
                    <Box key={playerIndex}>
                      {renderUsers(sectionIndex, playerIndex)}
                    </Box>
                  ))}
              </Grid>
            ))}
        </>
      )}
    </Grid>
  );
}
