import React, { forwardRef } from "react";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";

const SearchableMultipleSelect = forwardRef(
  (
    {
      name,
      label,
      control,
      defaultValue,
      onChange,
      helperText,
      options,
      optionLabel,
      ...props
    },
    ref
  ) => {
    const labelId = `${name}-label`;

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || []}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormControl
            sx={{ mt: 1, mb: 0.5 }}
            fullWidth
            size="small"
            {...props}
          >
            <Autocomplete
              multiple
              id={name}
              options={options}
              value={value}
              onChange={(_, newValue) => onChange(newValue)}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option[optionLabel]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={label}
                  error={false}
                  helperText={helperText}
                />
              )}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        )}
      />
    );
  }
);

export default SearchableMultipleSelect;
