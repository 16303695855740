import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "../services/user.service";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

export const addUserAsync = createAsyncThunk(
  "user/addUser",
  async (credentials, thunkAPI) => {
    try {
      const user = await userService.addUser(credentials);
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async (credentials, thunkAPI) => {
    try {
      const user = await userService.updateUser(credentials);
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUsersAsync = createAsyncThunk(
  "user/getUsers",
  async (_, thunkAPI) => {
    try {
      const users = await userService.getUsers();
      console.log("users", users);
      return users;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUsersAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
