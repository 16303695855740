import React, { forwardRef } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import { FormHelperText, useScrollTrigger } from "@mui/material";

const PrimarySelect = forwardRef(
  (
    {
      name,
      label,
      control,
      defaultValue,
      onChange,
      helperText,
      children,
      ...props
    },
    ref
  ) => {
    const labelId = `${name}-label`;

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormControl
            sx={{ mt: 1, mb: 0.5 }}
            fullWidth
            size="small"
            {...props}
          >
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              labelId={labelId}
              label={label}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            >
              {children}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        )}
      />
    );
  }
);

export default PrimarySelect;
