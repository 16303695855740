import React, { useState } from "react";
import { Box, Grid, ListItem, Typography, List } from "@mui/material";

function Players() {
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [playersAddedToTeam, setPlayersAddedToTeam] = useState([]);

  const maxPlayersPerTeam = 2;
  const maxTeamPerGroup = 4;

  const addToTeam = (player) => {
    if (playersAddedToTeam.includes(player.id)) {
      alert("Player already added to a team.");
      return;
    }

    const teamIndex = teams.findIndex(
      (team) => team.length < maxPlayersPerTeam
    );
    if (teamIndex !== -1) {
      const updatedTeams = [...teams];
      updatedTeams[teamIndex] = [...updatedTeams[teamIndex], player];
      setTeams(updatedTeams);
      setPlayersAddedToTeam([...playersAddedToTeam, player.id]);
    } else {
      setTeams([...teams, [player]]);
      setPlayersAddedToTeam([...playersAddedToTeam, player.id]);
    }
  };

  const addToGroup = (team) => {
    const groupIndex = groups.findIndex(
      (group) => group.length < maxTeamPerGroup
    );
    if (groupIndex !== -1) {
      const updatedGroups = [...groups];
      updatedGroups[groupIndex] = [...updatedGroups[groupIndex], team];
      setGroups(updatedGroups);
    } else {
      setGroups([...groups, [team]]);
    }
  };

  const swapPlayersBetweenTeams = (
    sourceTeamIndex,
    sourcePlayerIndex,
    targetTeamIndex,
    targetPlayerIndex
  ) => {
    const updatedTeams = [...teams];
    const sourceTeam = updatedTeams[sourceTeamIndex];
    const targetTeam = updatedTeams[targetTeamIndex];
    const sourcePlayer = sourceTeam.splice(sourcePlayerIndex, 1)[0];
    const targetPlayer = targetTeam.splice(targetPlayerIndex, 1)[0];
    sourceTeam.splice(sourcePlayerIndex, 0, targetPlayer);
    targetTeam.splice(targetPlayerIndex, 0, sourcePlayer);
    setTeams(updatedTeams);
  };

  const swapTeamsWithinGroup = (
    sourceGroupIndex,
    sourceTeamIndex,
    targetGroupIndex,
    targetTeamIndex
  ) => {
    const updatedGroups = [...groups];
    const sourceGroup = updatedGroups[sourceGroupIndex];
    const targetGroup = updatedGroups[targetGroupIndex];
    const sourceTeam = sourceGroup.splice(sourceTeamIndex, 1)[0];
    const targetTeam = targetGroup.splice(targetTeamIndex, 1)[0];
    sourceGroup.splice(sourceTeamIndex, 0, targetTeam);
    targetGroup.splice(targetTeamIndex, 0, sourceTeam);
    setGroups(updatedGroups);
  };

  const firstNames = [
    "John",
    "Emily",
    "Michael",
    "Sophia",
    "David",
    "Emma",
    "James",
    "Olivia",
    "Daniel",
    "Ava",
  ];
  const lastNames = [
    "Smith",
    "Johnson",
    "Williams",
    "Jones",
    "Brown",
    "Davis",
    "Miller",
    "Wilson",
    "Moore",
    "Taylor",
  ];

  function generateFirstName() {
    return firstNames[Math.floor(Math.random() * firstNames.length)];
  }

  function generateLastName() {
    return lastNames[Math.floor(Math.random() * lastNames.length)];
  }

  const generatePlayers = () => {
    const players = [];
    for (let i = 1; i <= 20; i++) {
      const firstName = generateFirstName();
      const lastName = generateLastName();
      players.push({
        id: i,
        name: `${firstName} ${lastName}`,
        position: i % 3 === 0 ? "Center" : i % 2 === 0 ? "Guard" : "Forward",
        height: `${Math.floor(Math.random() * 12) + 5}'${Math.floor(
          Math.random() * 12
        )}"`,
        age: Math.floor(Math.random() * 10) + 20,
        nationality: i % 2 === 0 ? "USA" : "Canada",
      });
    }
    return players;
  };
  const players = generatePlayers();

  const clearTeams = () => {
    setTeams([]);
    setPlayersAddedToTeam([]);
  };

  const clearGroups = () => {
    setGroups([]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="h2">All Players</Typography>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Position</th>
              <th>Height</th>
              <th>Age</th>
              <th>Nationality</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player) => (
              <tr key={player.id}>
                <td>{player.name}</td>
                <td>{player.position}</td>
                <td>{player.height}</td>
                <td>{player.age}</td>
                <td>{player.nationality}</td>
                <td>
                  <button onClick={() => addToTeam(player)}>Add to Team</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography variant="h2">Teams</Typography>
        {teams.map((team, index) => (
          <Box key={index}>
            <Typography variant="h3">Team {index + 1}</Typography>
            <ul>
              {team.map((player) => (
                <li key={player.id}>{player.name}</li>
              ))}
            </ul>
            <button onClick={() => addToGroup(team)}>Add to Group</button>
          </Box>
        ))}
        <button onClick={clearTeams}>Clear Teams</button>
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography variant="h2">Groups</Typography>
        {groups.map((group, index) => (
          <Box key={index}>
            <Typography variant="h3">Group {index + 1}</Typography>
            <List>
              {group.map((team) => (
                <ListItem key={team[0].id}>
                  Team {teams.findIndex((t) => t === team) + 1} ({team.length}{" "}
                  players)
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
        <button onClick={clearGroups}>Clear Groups</button>
      </Grid>
    </Grid>
  );
}

export default Players;
