import { ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect } from "react";
// routes
import { Route, Routes, Navigate, Outlet } from "react-router-dom";

// pages
// portal

import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";

// admin
import ClubList from "./pages/admin/club/ClubList";
import EventsList from "./pages/admin/events/EventsList";
import NewsList from "./pages/admin/news/NewsList";
import UserList from "./pages/admin/user/UserList";

// layout
import LoginLayout from "./layouts/portal/LoginLayout";
// admin
import AdminRootLayout from "./layouts/admin/AdminRootLayout";
import ClubAdd from "./pages/admin/club/ClubAdd";
import ClubView from "./pages/admin/club/ClubView";
import EventAdd from "./pages/admin/events/EventAdd";
import EventSections from "./pages/admin/events/EventSections";
import EventsView from "./pages/admin/events/EventsView";
import NewsAdd from "./pages/admin/news/NewsAdd";
import NewsView from "./pages/admin/news/NewsView";
import UserAdd from "./pages/admin/user/UserAdd";
import UserView from "./pages/admin/user/UserView";
import Players from "./pages/events/players";
import PrivacyPolicy from "./pages/privacyPolicy";

import PBAEvent from "./pages/admin/events/PBAEvent";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./helpers";
import {
  clearAlert,
  clearAlertWithDelay,
  setAlertError,
} from "./slices/alert.slice";

import { useOnlineStatus } from "./providers/onlineStatusProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0A2D33",
      light: "#0D3A42",
      dark: "#071D21",
    },
    secondary: {
      main: "#205E76",
      light: "#246B87",
      dark: "#102F38",
    },
    success: {
      main: "#9faf83",
      light: "#CADEA6",
      dark: "#7F8C69",
    },
    warning: {
      main: "#C2162F",
    },
    dark: { main: "#161715" },
    light: { main: "#f1f1f1" },
    white: { main: "#fff" },
  },

  typography: {
    htmlFontSize: 18,

    fontFamily: ["Poppins"],
    h1: {
      fontSize: "2.5rem",
      fontWeight: "normal",
      color: "secondary",
      // fontVariant: 'all-small-caps',
      lineHeight: "2rem",
    },
    h2: {},
    h3: {
      fontSize: "1.8rem",
      fontWeight: "bold",
      color: "secondary",
      fontVariant: "all-small-caps",
      lineHeight: "3rem",
    },
    h4: {},
    h5: {},
    h6: {
      fontWeight: "bold",
      fontVariant: "all-small-caps",
      lineHeight: "0.8rem",
    },
  },
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PrivateRoute = ({ element, ...props }) => {
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  return isLoggedIn ? (
    <AdminRootLayout>
      <Outlet />
    </AdminRootLayout>
  ) : (
    <Navigate to="/login" />
  );
};

const AuthRoute = ({ element, ...props }) => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  return user ? (
    <Navigate to="/admin/user-list" />
  ) : (
    <LoginLayout>
      <Outlet />
    </LoginLayout>
  );
};

function App() {
  const { message, type, open } = useSelector((state) => state.alert);
  const onlineStatus = useOnlineStatus();
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(clearAlert());
    });
  }, [dispatch]);

  useEffect(() => {
    console.log("onlineStatus", onlineStatus);
    if (!onlineStatus) {
      dispatch(
        setAlertError("You are offline. Please check your internet connection.")
      );
      dispatch(clearAlertWithDelay());
    }
  }, [onlineStatus]);

  const handleClose = (event, reason) => {
    dispatch(clearAlert());
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
      <Routes>
        <Route path="/" element={<AuthRoute />}>
          <Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>

        {/* Admin Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/admin/club-list" element={<ClubList />} />
          <Route path="/admin/club-add" element={<ClubAdd />} />
          <Route path="/admin/user-list" element={<UserList />} />
          <Route path="/admin/events-list" element={<EventsList />} />
          <Route path="/admin/event-add" element={<EventAdd />} />
          <Route path="/admin/event-section" element={<EventSections />} />
          <Route path="/admin/players" element={<Players />} />
          <Route path="/admin/news-list" element={<NewsList />} />
          <Route path="/admin/news-add" element={<NewsAdd />} />
          <Route path="/admin/user-view" element={<UserView />} />
          <Route path="/admin/user-add" element={<UserAdd />} />
          <Route path="/admin/news-view" element={<NewsView />} />
          <Route path="/admin/events-view" element={<EventsView />} />
          <Route path="/admin/club-view" element={<ClubView />} />
          <Route path="/admin/pba-event" element={<PBAEvent />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
