import React from "react";
import { Button, useTheme, useMediaQuery } from "@mui/material";

const PrimaryButton = ({ children, ...props }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Button
      variant="contained"
      color="primary"
      size={mobile ? "small" : "default"}
      sx={{ margin: theme.spacing(2, 1, 2) }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
