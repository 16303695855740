import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { getUsersAsync } from "../../../slices/user.slice";
import { Grid, List } from "@mui/material";
import PrimaryButton from "../../../components/ui/primaryButton";

function UserList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [usersWithId, setUsersWithId] = useState([]);

  React.useEffect(() => {
    try {
      dispatch(getUsersAsync())
        .unwrap()
        .then((res) => {
          const newUsers = res.map((user) => ({ ...user, id: user._id }));
          setUsersWithId(newUsers);
        })
        .catch((error) => {
          console.error("error failed:", error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const columns = [
    { field: "status", headerName: "Status", width: 120 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <h1>Users</h1>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <PrimaryButton href="/admin/user-add">Add new user</PrimaryButton>
        </Grid>
      </Grid>
      <DataGrid
        rows={usersWithId}
        columns={columns}
        editMode="row"
        onRowClick={(data, e) => {
          const path = "../user-add";
          navigate(path, { state: { id: data.row.id } });
        }}
      />
    </div>
  );
}

export default UserList;
