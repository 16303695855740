import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/ui/primaryButton";

const columns = [
  { field: "id", headerName: "ID", width: 17 },
  { field: "title", headerName: "Title", width: 200 },
  { field: "body", headerName: "Article", width: 200 },
  { field: "date", headerName: "Date", width: 150 },
];

const rows = [
  {
    id: 1,
    title: "Welcome",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    date: "20-05-23",
  },
  {
    id: 2,
    title: "And the winner is...",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    date: "23-11-23",
  },
];

function NewsList() {
  const navigate = useNavigate();
  return (
    <div style={{ height: 400, width: "100%" }}>
      <h1>News</h1>
      <PrimaryButton href="/admin/news-add">Add news</PrimaryButton>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        onRowClick={(data, e) => {
          console.log(data.id);
          console.log(data.row.title);
          const path = "../news-view";
          navigate(path, {
            state: {
              id: data.id,
              title: data.row.title,
              body: data.row.body,
              date: data.row.date,
            },
          });
        }}
      />
    </div>
  );
}

export default NewsList;
