import React from "react";
import { Link } from "react-router-dom";

function EventsView() {
  return (
    <div>
      <Link to={"../events-list"} className="breadCrumb">
        {"<"}back
      </Link>
      <h1>EventsView</h1>
    </div>
  );
}

export default EventsView;
