import { Divider, Grid, MenuItem, Typography } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CheckboxInput from "../../../components/ui/checkbox";
import Input from "../../../components/ui/input";
import { addUserAsync, updateUserAsync } from "../../../slices/user.slice";
import PrimaryButton from "../../../components/ui/primaryButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getEventsAsync } from "../../../slices/event.slice";
import MultipleSelect from "../../../components/ui/searchDropDown";
import SearchDropDown from "../../../components/ui/searchDropDown";

const schema = yup.object().shape({
  firstName: yup.string().required("Name is required"),
  lastName: yup.string().required("Surname is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  membershipNr: yup.string().required("Membership nr is required"),
  pbaNr: yup.string().required("pbaNr is required"),
  //description: yup.string().required("Description is required"),
  isFeaturedPlayer: yup.boolean(),
  pbaTournament: yup.array(),
});

function NewsAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userId = location.state ? location.state.id : null;
  const events = useSelector((state) => state.events.events);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (userId) {
      try {
        dispatch(updateUserAsync(data))
          .unwrap()
          .then(() => {
            navigate("/admin/user-list");
          })
          .catch((error) => {
            console.error("error failed:", error);
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        dispatch(addUserAsync(data))
          .unwrap()
          .then(() => {
            navigate("/admin/user-list");
          })
          .catch((error) => {
            console.error("error failed:", error);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const user = useSelector((state) =>
    state.users.users.find((u) => u._id === userId)
  );

  React.useEffect(() => {
    if (user) {
      Object.keys(user).forEach((key) => {
        setValue(key, user[key]);
      });
    }
  }, [user, setValue]);

  React.useEffect(() => {
    dispatch(getEventsAsync());
  }, []);

  return (
    <div>
      <Typography variant="h1" sx={{ mb: 3 }}>
        {userId ? "Edit User" : "Add New User"}{" "}
      </Typography>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Input
              {...register("firstName")}
              label="Name"
              control={control}
              helperText={errors.firstName && errors.firstName?.message}
              error={!!errors.firstName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...register("lastName")}
              label="Surname"
              control={control}
              helperText={errors.lastName && errors.lastName?.message}
              error={!!errors.lastName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...register("email")}
              label="Email"
              control={control}
              helperText={errors.email && errors.email?.message}
              error={!!errors.email}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...register("membershipNr")}
              label="Membership nr"
              control={control}
              helperText={errors.membershipNr && errors.membershipNr?.message}
              error={!!errors.membershipNr}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...register("pbaNr")}
              label="pbaNr"
              control={control}
              helperText={errors.pbaNr && errors.pbaNr?.message}
              error={!!errors.pbaNr}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SearchDropDown
              id="events"
              name="events"
              label="Events"
              control={control}
              error={!!errors.events}
              helperText={errors?.events?.message}
              options={events}
              optionLabel="title"
              required
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CheckboxInput
              {...register("isFeaturedPlayer")}
              id="isFeaturedPlayer"
              type="checkbox"
              label="Featured player of main contenders"
              control={control}
              helperText={
                errors.isFeaturedPlayer && errors.isFeaturedPlayer?.message
              }
              error={!!errors.isFeaturedPlayer}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CheckboxInput
              {...register("pbaTournament")}
              id="PBA2023"
              type="checkbox"
              value="PBA2023"
              label="Add to PBA tournament"
              control={control}
              helperText={errors.pbaTournament && errors.pbaTournament?.message}
              error={!!errors.pbaTournament}
            />

            <CheckboxInput
              {...register("pbaTournament")}
              id="PBA2023"
              type="checkbox"
              value="PBA2023"
              label={2023}
              control={control}
              helperText={errors.pbaTournament && errors.pbaTournament?.message}
              error={!!errors.pbaTournament}
            />
            <CheckboxInput
              {...register("pbaTournament")}
              id="PBA2022"
              type="checkbox"
              value="PBA2022"
              label={2022}
              control={control}
              helperText={errors.pbaTournament && errors.pbaTournament?.message}
              error={!!errors.pbaTournament}
            />
          </Grid>
          {/* <Grid item xs={6}>
          <label for='userProfile'><b>Profile picture:</b></label>
            <Input {...register('userProfile')} id='userProfile' />
          </Grid> */}
          {/* <Grid item xs={12}>
            <label><b>Club:</b></label>
            <select {...register('club')}>
              <option value="0">-- Not Selected --</option>
              <option value="durbanville">Durbanville</option>
              <option value="everstdal">Everstdal</option>
            </select>
          </Grid> */}
          <Divider sx={{ height: "50px" }} />

          <Grid item xs={6}>
            <PrimaryButton type="submit">
              {userId ? "Update" : "Submit"}
            </PrimaryButton>

            <PrimaryButton href="/admin/user-list" variant="outlined">
              Cancel
            </PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default NewsAdd;
