import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import SideBar from "./SideBar";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

function AdminRootLayout() {
  const [open, setOpen] = React.useState(false);
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  // Check if user is logged in, otherwise redirect to login
  if (!isLoggedIn) {
    return <Navigate to="/Login" replace />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SideBar open={open} setOpen={setOpen} />
      <Main open={open}>
        <Outlet />
      </Main>
    </Box>
  );
}

export default AdminRootLayout;
