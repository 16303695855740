import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth.slice";
import alertReducer from "../slices/alert.slice";
import usersReducer from "../slices/user.slice";
import eventsReducer from "../slices/event.slice";
import { StateLoader } from "./stateLoader";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    users: usersReducer,
    events: eventsReducer,
  },
  preloadedState: StateLoader.loadState(),
});

store.subscribe(() => {
  StateLoader.saveState(store.getState());
});
