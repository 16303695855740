import { Divider, Grid } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "../../../components/ui/input";
import Radio from "@mui/material/Radio";
import CustomRadioGroup from "../../../components/ui/radioGroup";
import { useMediaQuery } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import axios from "axios";
import PrimaryButton from "../../../components/ui/primaryButton";

function NewsAdd() {
  const { control, register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    try {
      await axios
        .post("/api/club", data)
        .then((response) => console.log(response))
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div>
      <h2>Add news</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Input {...register("title")} label="Title" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input {...register("subTitle")} label="Sub-title" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input {...register("body")} label="Body" multiline />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomRadioGroup
              row
              control={control}
              aria-label="region"
              name="region"
              xs={{ margin: 1 }}
            >
              <FormControlLabel
                value={"club"}
                control={<Radio />}
                color="primary"
                label={"Club"}
              />
              <FormControlLabel
                value={"district"}
                control={<Radio />}
                color="primary"
                label={"District"}
              />
            </CustomRadioGroup>
          </Grid>
          <Divider sx={{ height: "50px" }} />
          <Grid item xs={6}>
            <PrimaryButton type="submit">Submit</PrimaryButton>

            <PrimaryButton href="/admin/club-list" variant="outlined">
              Cancel
            </PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default NewsAdd;
