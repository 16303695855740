import axios from "axios";
import React, { useState, useEffect } from "react";

const PING_RESOURCE = "/api/ping";
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;

const checkOnlineStatus = () => {
  return new Promise(async (resolve, reject) => {
    // If the browser has no network connection return offline
    if (!navigator.onLine) return navigator.onLine;

    axios
      .get(PING_RESOURCE, { timeout: TIMEOUT_TIME_MS })
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

const OnlineStatusContext = React.createContext(true);
export const useOnlineStatus = () => React.useContext(OnlineStatusContext);

const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true);

  const checkStatus = async () => {
    await checkOnlineStatus().then((online) => {
      setOnlineStatus(online);
    });
  };

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    console.log("online", onlineStatus);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, [onlineStatus]);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export default OnlineStatusProvider;
