import { Divider, Grid, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import * as React from "react";
import { useForm } from "react-hook-form";
import Input from "../../../components/ui/input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import "react-datepicker/dist/react-datepicker.css";
import GreatDatePicker from "../../../components/ui/datePicker";
import PrimaryButton from "../../../components/ui/primaryButton";
import CustomRadioGroup from "../../../components/ui/radioGroup";
import PrimarySelect from "../../../components/ui/select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { addEventAsync } from "../../../slices/event.slice";

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  category: yup.string().required("Category is required"),
  region: yup.string().required("Region is required"),
  gender: yup.string().required("Gender is required"),
  subCat: yup.string().required("Sub-Category is required"),
  startDate: yup.date().required("Start Date is required"),
  endDate: yup.date().required("End Date is required"),
  location: yup.string().required("Location is required"),
  description: yup.string().required("Description is required"),
});

function EventAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const eventId = location.state ? location.state.id : null;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    console.log("data to submit", data);
    dispatch(addEventAsync(data))
      .unwrap()
      .then(() => {
        navigate("/admin/events-list");
      })
      .catch((error) => {
        console.error("failed:", error);
      });
  };

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const event = useSelector((state) =>
    state.events.events.find((u) => u._id === eventId)
  );

  React.useEffect(() => {
    if (event) {
      Object.keys(event).forEach((key) => {
        setValue(key, event[key]);
      });
    }
  }, [event, setValue]);

  return (
    <div>
      <h2>Add new event</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Input
              {...register("title")}
              label="Title"
              control={control}
              helperText={errors.title && errors.title?.message}
              error={!!errors.title}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <PrimarySelect
              label="Category"
              name="category"
              control={control}
              helperText={errors.category && errors.category?.message}
              error={!!errors.category}
            >
              <MenuItem value="singles">Singles</MenuItem>
              <MenuItem value="doubles">Doubles</MenuItem>
              <MenuItem value="triples">Triples</MenuItem>
              <MenuItem value="fours">Fours</MenuItem>
              <MenuItem value="fives">Fives</MenuItem>
              <MenuItem value="league">League</MenuItem>
            </PrimarySelect>
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomRadioGroup
              row={isMediumScreen}
              control={control}
              {...register("region")}
              aria-label="region"
              name="region"
              xs={{ margin: 1 }}
              helperText={errors.region && errors.region?.message}
              error={!!errors.region}
            >
              <FormControlLabel
                value={"club"}
                control={<Radio />}
                color="primary"
                label={"Club"}
              />
              <FormControlLabel
                value={"district"}
                control={<Radio />}
                color="primary"
                label={"District"}
              />
            </CustomRadioGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomRadioGroup
              row={isMediumScreen}
              control={control}
              {...register("gender")}
              aria-label="gender"
              name="gender"
              xs={{ margin: 1 }}
              helperText={errors.gender && errors.gender?.message}
              error={!!errors.gender}
            >
              <FormControlLabel
                value={"female"}
                control={<Radio />}
                color="primary"
                label={"Female"}
              />
              <FormControlLabel
                value={"male"}
                control={<Radio />}
                color="primary"
                label={"Male"}
              />
              <FormControlLabel
                value={"mixed"}
                control={<Radio />}
                color="primary"
                label={"Mixed"}
              />
            </CustomRadioGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...register("location")}
              label="Location"
              control={control}
              helperText={errors.location && errors.location?.message}
              error={!!errors.location}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Input
              {...register("description")}
              label="Description"
              control={control}
              helperText={errors.description && errors.description?.message}
              error={!!errors.description}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <GreatDatePicker
              name={"startDate"}
              control={control}
              label="Start Date"
              openTo={"year"}
              helperText={errors.startDate && errors.startDate?.message}
              error={!!errors.startDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <GreatDatePicker
              name={"endDate"}
              control={control}
              label="End Date"
              openTo={"year"}
              helperText={errors.endDate && errors.endDate?.message}
              error={!!errors.endDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PrimarySelect
              label="Sub-Category"
              {...register("subCat")}
              name="subCat"
              control={control}
              helperText={errors.subCat && errors.subCat?.message}
              error={!!errors.subCat}
            >
              <MenuItem value="handicap">Handicap</MenuItem>
              <MenuItem value="novice">Novice</MenuItem>
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="veteran">Veteran</MenuItem>
              <MenuItem value="open-drawn">Open Drawn</MenuItem>
              <MenuItem value="mixed">Mixed</MenuItem>
              <MenuItem value="2-bowl">2-Bowl</MenuItem>
              <MenuItem value="pro-10">Pro-10</MenuItem>
              <MenuItem value="open-a38">Open+A38</MenuItem>
              <MenuItem value="midweek">Midweek</MenuItem>
            </PrimarySelect>
          </Grid>
          <Divider sx={{ height: "50px" }} />
          <Grid item xs={12}>
            <PrimaryButton type="submit">Submit</PrimaryButton>
            <Button href="/admin/events-list">Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default EventAdd;
