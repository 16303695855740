import React, { forwardRef } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

const CheckboxInput = forwardRef((props, ref) => {
  const { label, ...otherProps } = props;

  return (
    <FormControlLabel
      control={<Checkbox inputRef={ref} {...otherProps} />}
      label={label}
    />
  );
});

export default CheckboxInput;
