import React from "react";
import {
  RadioGroup,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";

const CustomRadioGroup = ({
  children,
  control,
  name,
  label,
  row,
  ...props
}) => {
  return (
    <FormControl error={props.error}>
      <FormLabel>
        {name && name.charAt(0).toUpperCase() + name.slice(1)}
      </FormLabel>

      <Controller
        control={control}
        name={name}
        defaultValue={props.value || ""}
        render={({ field: { onChange, value } }) => (
          <RadioGroup onChange={onChange} value={value} row={row && row}>
            {children}
          </RadioGroup>
        )}
      />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomRadioGroup;
