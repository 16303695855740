const loadState = () => {
  try {
    let serializedState = localStorage.getItem("clubmaster.com");

    if (serializedState === null) {
      return initializeState();
    }

    const newState = JSON.parse(serializedState);

    if (!newState.auth) {
      newState.auth = initializeAuthState();
    }

    return newState;
  } catch (err) {
    return initializeState();
  }
};

const saveState = (state) => {
  try {
    if (state.auth && state.auth.user === null) {
      localStorage.removeItem("clubmaster.com");
    } else {
      let serializedState = JSON.stringify(state);
      localStorage.setItem("clubmaster.com", serializedState);
    }
  } catch (err) {
    // Handle errors if any
  }
};

const initializeState = () => {
  return {};
};

const initializeAuthState = () => {
  return {
    user: null,
    error: null,
    loading: false,
  };
};

export const StateLoader = {
  loadState,
  saveState,
  initializeState,
};
