import React from 'react'
import { Link, useLocation} from 'react-router-dom';

function NewsView() {
    const location = useLocation();
    
    return (
        <div>
            <Link to={'../news-list'} className='breadCrumb' >{'<'}back</Link>
            <h1>{location.state.title}</h1>
            <h3>{location.state.date}</h3>
            <p>{location.state.body}</p>
        </div>
    )
}

export default NewsView