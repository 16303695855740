import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function ClubView() {
    const location = useLocation();
  return (
    <div>
        <Link to={'../club-list'} className='breadCrumb' >{'<'}back</Link>
        <h1>{location.state.name}</h1></div>
  )
}

export default ClubView