import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TextField } from "@mui/material";

function GreatDatePicker({
  required,
  name,
  label,
  control,
  helperText,
  ...props
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name={name}
        control={control}
        defaultValue={
          props.defaultCalendarMonth
            ? props.defaultCalendarMonth
            : props.defaultValue
            ? props.defaultValue
            : null
        }
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MobileDatePicker
            label={label}
            showToolbar={false}
            inputFormat={props.inputFormat || "DD/MM/YYYY"}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                id={name}
                margin="dense"
                size="small"
                fullWidth
                helperText={error ? error.message : helperText}
                {...params}
                required={required}
                error={Boolean(error)}
              />
            )}
            {...props}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default GreatDatePicker;
