import axios from "axios";
import { config } from "../features/config";

const addUser = async (credentials) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(`${config.user_server}`, credentials, requestOptions)
    .then((response) => {
      return response.data;
    });
};

const getUsers = async (credentials) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .get(`${config.user_server}`, credentials, requestOptions)
    .then((response) => {
      return response.data;
    });
};

const updateUser = async (credentials) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .put(
      `${config.user_server}/${credentials._id}`,
      credentials,
      requestOptions
    )
    .then((response) => {
      return response.data;
    });
};

export const userService = {
  addUser,
  updateUser,
  getUsers,
};
