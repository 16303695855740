import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../services/auth.service";
import { setAlertError, setAlertSuccess } from "./alert.slice";
import { history } from "../helpers";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { user, loggedIn: true } : { loggedIn: false };

export const loginUser = createAsyncThunk(
  "auth/login",
  async (data, thunkAPI) => {
    return await authService
      .login(data)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response));
        thunkAPI.dispatch(setAlertSuccess("Signed in successfully."));
        return thunkAPI.fulfillWithValue(response);
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (data, thunkAPI) => {
    return await authService
      .register(data)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response));
        thunkAPI.dispatch(setAlertSuccess("Signed up successfully."));
        return thunkAPI.fulfillWithValue(response);
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, thunkAPI) => {
    localStorage.removeItem("user");
    history.push("/signin");
    return;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedIn = true;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedIn = true;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = null;
        state.loggedIn = false;
      });
  },
});

export default authSlice.reducer;
