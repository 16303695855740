import { createSlice } from "@reduxjs/toolkit";

const initialState = { message: "", type: "", open: false };

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlertSuccess: (state, action) => {
      return { type: "success", message: action.payload, open: true };
    },
    setAlertError: (state, action) => {
      return { type: "error", message: action.payload, open: true };
    },
    setAlertInfo: (state, action) => {
      return { type: "info", message: action.payload, open: true };
    },
    setAlertWarning: (state, action) => {
      return { type: "warning", message: action.payload, open: true };
    },
    clearAlert: () => {
      return { open: false };
    },
  },
});

export const {
  setAlertSuccess,
  setAlertError,
  setAlertInfo,
  setAlertWarning,
  clearAlert,
} = alertSlice.actions;

export const clearAlertWithDelay = () => (dispatch) => {
  setTimeout(() => {
    dispatch(clearAlert());
  }, 30000);
};

export default alertSlice.reducer;
