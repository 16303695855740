import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SportsIcon from "@mui/icons-material/Sports";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LogoutIcon from "@mui/icons-material/Logout";

// icons
export const HOME_ICON = <HomeIcon className="icon" />;
export const CLUB_ICON = <HomeWorkIcon className="icon" />;
export const NEWS_ICON = <ArticleIcon className="icon" />;
export const CALENDAR_ICON = <CalendarMonthIcon className="icon" />;
export const COMPETITIONS_ICON = <EmojiEventsIcon className="icon" />;
export const MEMBERSHIP_ICON = <GroupsIcon className="icon" />;
export const LIVE_SCORE_ICON = <SportsIcon className="icon" />;
export const LOG_OUT_ICON = <LogoutIcon className="icon" />;
