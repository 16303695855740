import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { eventService } from "../services/event.service";

const initialState = {
  events: [],
  eventUsers: [],
  teams: [], // Add teams to the initial state
  loading: false,
  error: null,
};

const dummyUser = [
  {
    events: [
      {
        format: [],
        _id: "65e1878f10e14111471f6b28",
        description: "Illum amet quos dolores repudiandae amet.",
        location:
          "Fuga harum omnis doloremque sed veritatis minus illum occaecati quibusdam.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "male",
        region: "district",
        category: "doubles",
        title: "District Web Orchestrator",
        createdAt: "2024-03-01T07:45:19.712Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e1863e331dc8109e53295a",
        description: "Mollitia nam quaerat.",
        location: "Est porro nam aspernatur quos unde dolores id.",
        endDate: "2017-12-31T22:00:00.000Z",
        startDate: "2004-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Direct Response Director",
        createdAt: "2024-03-01T07:39:42.895Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e186a27054b111009b22ab",
        description:
          "Repellendus adipisci ipsa dolorem cumque itaque numquam consequatur temporibus.",
        location:
          "Placeat ullam excepturi explicabo itaque veritatis numquam natus nulla ab.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Product Solutions Architect",
        createdAt: "2024-03-01T07:41:22.556Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829c7390acd46962920a4",
        description:
          "Pariatur non repellat unde maiores facere voluptatibus odit fugiat asperiores.",
        location:
          "Accusamus recusandae debitis reprehenderit veniam molestias excepturi laudantium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2024-12-31T22:00:00.000Z",
        subCat: "veteran",
        gender: "mixed",
        region: "district",
        category: "doubles",
        title: "Customer Research Representative",
        createdAt: "2024-03-06T08:31:03.972Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e187ca10e14111471f6b2c",
        description: "Iusto nostrum maiores unde non.",
        location:
          "Quam beatae labore occaecati molestias nostrum labore nobis quia.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Web Facilitator",
        createdAt: "2024-03-01T07:46:18.763Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829fe390acd46962920ac",
        description: "Adipisci quia culpa.",
        location:
          "Aspernatur ducimus asperiores laboriosam corrupti quaerat doloribus voluptatum fugiat.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2024-12-31T22:00:00.000Z",
        subCat: "veteran",
        gender: "male",
        region: "district",
        category: "doubles",
        title: "Dynamic Response Specialist",
        createdAt: "2024-03-06T08:31:58.827Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e82a3a390acd46962920b4",
        description: "Voluptates cupiditate asperiores.",
        location:
          "Deleniti sit dignissimos reiciendis temporibus tempore explicabo.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "male",
        region: "club",
        category: "doubles",
        title: "Investor Brand Director",
        createdAt: "2024-03-06T08:32:58.596Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e83b6075af61497c2d3e6f",
        description: "Doloribus dicta molestiae.",
        location: "Suscipit fugiat ex.",
        endDate: "2030-12-31T22:00:00.000Z",
        startDate: "2001-12-31T22:00:00.000Z",
        subCat: "handicap",
        gender: "mixed",
        region: "club",
        category: "doubles",
        title: "Chief Quality Developer",
        createdAt: "2024-03-06T09:46:08.355Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: ["PBA2023", "PBA2022"],
    isFeaturedPlayer: false,
    _id: "65e18341d8268b11de742280",
    email: "your.email+fakedata29226@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T07:26:57.624Z",
    firstName: "Nickolas",
    lastName: "Walsh",
    membershipNr: "54",
    pbaNr: "Tempore itaque cumque hic aspernatur.",
    updatedAt: "2024-03-06T09:53:26.703Z",
  },
  {
    events: [
      {
        format: [],
        _id: "65e1863e331dc8109e53295a",
        description: "Mollitia nam quaerat.",
        location: "Est porro nam aspernatur quos unde dolores id.",
        endDate: "2017-12-31T22:00:00.000Z",
        startDate: "2004-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Direct Response Director",
        createdAt: "2024-03-01T07:39:42.895Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829c7390acd46962920a4",
        description:
          "Pariatur non repellat unde maiores facere voluptatibus odit fugiat asperiores.",
        location:
          "Accusamus recusandae debitis reprehenderit veniam molestias excepturi laudantium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2024-12-31T22:00:00.000Z",
        subCat: "veteran",
        gender: "mixed",
        region: "district",
        category: "doubles",
        title: "Customer Research Representative",
        createdAt: "2024-03-06T08:31:03.972Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829e2390acd46962920a8",
        description: "Alias exercitationem beatae.",
        location: "Nemo minima optio provident praesentium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "handicap",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Intranet Director",
        createdAt: "2024-03-06T08:31:30.159Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e1871b10e14111471f6b22",
        description: "Modi nisi ullam.",
        location:
          "Consectetur alias modi impedit dignissimos quo illum ipsum placeat totam.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Future Accountability Consultant",
        createdAt: "2024-03-01T07:43:23.423Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e1878f10e14111471f6b28",
        description: "Illum amet quos dolores repudiandae amet.",
        location:
          "Fuga harum omnis doloremque sed veritatis minus illum occaecati quibusdam.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "male",
        region: "district",
        category: "doubles",
        title: "District Web Orchestrator",
        createdAt: "2024-03-01T07:45:19.712Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e187ca10e14111471f6b2c",
        description: "Iusto nostrum maiores unde non.",
        location:
          "Quam beatae labore occaecati molestias nostrum labore nobis quia.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Web Facilitator",
        createdAt: "2024-03-01T07:46:18.763Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e82a26390acd46962920b0",
        description: "Qui nulla laboriosam ex alias perferendis.",
        location: "Ea blanditiis molestiae sequi mollitia.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "2-bowl",
        gender: "male",
        region: "club",
        category: "doubles",
        title: "Legacy Functionality Facilitator",
        createdAt: "2024-03-06T08:32:38.408Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: ["PBA2023", "PBA2022"],
    isFeaturedPlayer: false,
    _id: "65e18387d8268b11de7422b1",
    email: "your.email+fakedata77647@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T07:28:07.326Z",
    firstName: "Blair",
    lastName: "Kessler",
    membershipNr: "316",
    pbaNr: "Ad autem eveniet laborum tempore suscipit blanditiis.",
    updatedAt: "2024-03-06T08:35:14.619Z",
  },
  {
    events: [
      {
        format: [],
        _id: "65e18444616501042f704ce2",
        description: "Tempora minima architecto.",
        location: "Aspernatur earum voluptate.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Investor Accountability Director",
        createdAt: "2024-03-01T07:31:16.376Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829a5390acd46962920a0",
        description: "Corrupti voluptas saepe at omnis.",
        location: "Officia consectetur corporis.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "mixed",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "District Directives Supervisor",
        createdAt: "2024-03-06T08:30:29.998Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829e2390acd46962920a8",
        description: "Alias exercitationem beatae.",
        location: "Nemo minima optio provident praesentium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "handicap",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Intranet Director",
        createdAt: "2024-03-06T08:31:30.159Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829c7390acd46962920a4",
        description:
          "Pariatur non repellat unde maiores facere voluptatibus odit fugiat asperiores.",
        location:
          "Accusamus recusandae debitis reprehenderit veniam molestias excepturi laudantium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2024-12-31T22:00:00.000Z",
        subCat: "veteran",
        gender: "mixed",
        region: "district",
        category: "doubles",
        title: "Customer Research Representative",
        createdAt: "2024-03-06T08:31:03.972Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e1878f10e14111471f6b28",
        description: "Illum amet quos dolores repudiandae amet.",
        location:
          "Fuga harum omnis doloremque sed veritatis minus illum occaecati quibusdam.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "male",
        region: "district",
        category: "doubles",
        title: "District Web Orchestrator",
        createdAt: "2024-03-01T07:45:19.712Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e186a27054b111009b22ab",
        description:
          "Repellendus adipisci ipsa dolorem cumque itaque numquam consequatur temporibus.",
        location:
          "Placeat ullam excepturi explicabo itaque veritatis numquam natus nulla ab.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Product Solutions Architect",
        createdAt: "2024-03-01T07:41:22.556Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e82a26390acd46962920b0",
        description: "Qui nulla laboriosam ex alias perferendis.",
        location: "Ea blanditiis molestiae sequi mollitia.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "2-bowl",
        gender: "male",
        region: "club",
        category: "doubles",
        title: "Legacy Functionality Facilitator",
        createdAt: "2024-03-06T08:32:38.408Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e82a3a390acd46962920b4",
        description: "Voluptates cupiditate asperiores.",
        location:
          "Deleniti sit dignissimos reiciendis temporibus tempore explicabo.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "male",
        region: "club",
        category: "doubles",
        title: "Investor Brand Director",
        createdAt: "2024-03-06T08:32:58.596Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e82a56390acd46962920b8",
        description: "Architecto rerum fuga modi debitis amet.",
        location: "Nisi repellendus beatae aperiam consequatur ullam aliquid.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "mixed",
        gender: "female",
        region: "district",
        category: "doubles",
        title: "Corporate Creative Agent",
        createdAt: "2024-03-06T08:33:26.486Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e82a7b390acd46962920bc",
        description: "Ullam repellendus tenetur quo.",
        location: "Ad ab a ad.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "male",
        region: "club",
        category: "doubles",
        title: "Legacy Research Engineer",
        createdAt: "2024-03-06T08:34:03.137Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: ["PBA2023"],
    isFeaturedPlayer: false,
    _id: "65e1839ad8268b11de7422c0",
    email: "your.email+fakedata11291@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T07:28:26.585Z",
    firstName: "Lauryn",
    lastName: "Collins-Schuppe",
    membershipNr: "38",
    pbaNr: "Nisi cupiditate atque totam quibusdam dolorem.",
    updatedAt: "2024-03-06T08:35:28.647Z",
  },
  {
    events: [
      {
        format: [],
        _id: "65e1863e331dc8109e53295a",
        description: "Mollitia nam quaerat.",
        location: "Est porro nam aspernatur quos unde dolores id.",
        endDate: "2017-12-31T22:00:00.000Z",
        startDate: "2004-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Direct Response Director",
        createdAt: "2024-03-01T07:39:42.895Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e18444616501042f704ce2",
        description: "Tempora minima architecto.",
        location: "Aspernatur earum voluptate.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Investor Accountability Director",
        createdAt: "2024-03-01T07:31:16.376Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e187ca10e14111471f6b2c",
        description: "Iusto nostrum maiores unde non.",
        location:
          "Quam beatae labore occaecati molestias nostrum labore nobis quia.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Web Facilitator",
        createdAt: "2024-03-01T07:46:18.763Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829a5390acd46962920a0",
        description: "Corrupti voluptas saepe at omnis.",
        location: "Officia consectetur corporis.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "mixed",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "District Directives Supervisor",
        createdAt: "2024-03-06T08:30:29.998Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829e2390acd46962920a8",
        description: "Alias exercitationem beatae.",
        location: "Nemo minima optio provident praesentium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "handicap",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Intranet Director",
        createdAt: "2024-03-06T08:31:30.159Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829c7390acd46962920a4",
        description:
          "Pariatur non repellat unde maiores facere voluptatibus odit fugiat asperiores.",
        location:
          "Accusamus recusandae debitis reprehenderit veniam molestias excepturi laudantium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2024-12-31T22:00:00.000Z",
        subCat: "veteran",
        gender: "mixed",
        region: "district",
        category: "doubles",
        title: "Customer Research Representative",
        createdAt: "2024-03-06T08:31:03.972Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e186a27054b111009b22ab",
        description:
          "Repellendus adipisci ipsa dolorem cumque itaque numquam consequatur temporibus.",
        location:
          "Placeat ullam excepturi explicabo itaque veritatis numquam natus nulla ab.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Product Solutions Architect",
        createdAt: "2024-03-01T07:41:22.556Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: ["PBA2023"],
    isFeaturedPlayer: false,
    _id: "65e183add8268b11de7422d0",
    email: "your.email+fakedata72970@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T07:28:45.477Z",
    firstName: "Will",
    lastName: "Bergnaum",
    membershipNr: "458",
    pbaNr:
      "Voluptatem blanditiis eligendi laudantium neque doloribus officiis repellat provident voluptatum.",
    updatedAt: "2024-03-06T08:35:47.336Z",
  },
  {
    events: [
      {
        format: [],
        _id: "65e83b6075af61497c2d3e6f",
        description: "Doloribus dicta molestiae.",
        location: "Suscipit fugiat ex.",
        endDate: "2030-12-31T22:00:00.000Z",
        startDate: "2001-12-31T22:00:00.000Z",
        subCat: "handicap",
        gender: "mixed",
        region: "club",
        category: "doubles",
        title: "Chief Quality Developer",
        createdAt: "2024-03-06T09:46:08.355Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e82a26390acd46962920b0",
        description: "Qui nulla laboriosam ex alias perferendis.",
        location: "Ea blanditiis molestiae sequi mollitia.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "2-bowl",
        gender: "male",
        region: "club",
        category: "doubles",
        title: "Legacy Functionality Facilitator",
        createdAt: "2024-03-06T08:32:38.408Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829e2390acd46962920a8",
        description: "Alias exercitationem beatae.",
        location: "Nemo minima optio provident praesentium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "handicap",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Intranet Director",
        createdAt: "2024-03-06T08:31:30.159Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829a5390acd46962920a0",
        description: "Corrupti voluptas saepe at omnis.",
        location: "Officia consectetur corporis.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "mixed",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "District Directives Supervisor",
        createdAt: "2024-03-06T08:30:29.998Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: ["PBA2022"],
    isFeaturedPlayer: false,
    _id: "65e183c3d8268b11de7422e3",
    email: "your.email+fakedata71760@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T07:29:07.187Z",
    firstName: "Chase",
    lastName: "Schroeder",
    membershipNr: "99",
    pbaNr:
      "Accusamus molestiae necessitatibus assumenda odit ut corporis expedita sequi.",
    updatedAt: "2024-03-06T09:53:38.100Z",
  },
  {
    events: [],
    status: "true",
    pbaTournament: ["PBA2022"],
    isFeaturedPlayer: false,
    _id: "65e183d1d8268b11de7422f1",
    email: "your.email+fakedata75816@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T07:29:21.705Z",
    firstName: "Nils",
    lastName: "Beahan",
    membershipNr: "84",
    pbaNr:
      "Sit vel harum corporis maiores doloremque officiis aspernatur sed voluptatum.",
    updatedAt: "2024-03-01T07:29:21.705Z",
  },
  {
    events: [
      {
        format: [],
        _id: "65e18444616501042f704ce2",
        description: "Tempora minima architecto.",
        location: "Aspernatur earum voluptate.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Investor Accountability Director",
        createdAt: "2024-03-01T07:31:16.376Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e1871b10e14111471f6b22",
        description: "Modi nisi ullam.",
        location:
          "Consectetur alias modi impedit dignissimos quo illum ipsum placeat totam.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Future Accountability Consultant",
        createdAt: "2024-03-01T07:43:23.423Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e1878f10e14111471f6b28",
        description: "Illum amet quos dolores repudiandae amet.",
        location:
          "Fuga harum omnis doloremque sed veritatis minus illum occaecati quibusdam.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "male",
        region: "district",
        category: "doubles",
        title: "District Web Orchestrator",
        createdAt: "2024-03-01T07:45:19.712Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829a5390acd46962920a0",
        description: "Corrupti voluptas saepe at omnis.",
        location: "Officia consectetur corporis.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "mixed",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "District Directives Supervisor",
        createdAt: "2024-03-06T08:30:29.998Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e829c7390acd46962920a4",
        description:
          "Pariatur non repellat unde maiores facere voluptatibus odit fugiat asperiores.",
        location:
          "Accusamus recusandae debitis reprehenderit veniam molestias excepturi laudantium.",
        endDate: "2024-12-31T22:00:00.000Z",
        startDate: "2024-12-31T22:00:00.000Z",
        subCat: "veteran",
        gender: "mixed",
        region: "district",
        category: "doubles",
        title: "Customer Research Representative",
        createdAt: "2024-03-06T08:31:03.972Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: ["PBA2023", "PBA2023"],
    isFeaturedPlayer: false,
    _id: "65e183e6d8268b11de742301",
    email: "your.email+fakedata65731@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T07:29:42.198Z",
    firstName: "Janelle",
    lastName: "Klocko",
    membershipNr: "219",
    pbaNr: "Sunt voluptas iusto earum.",
    updatedAt: "2024-03-06T08:36:01.152Z",
  },
  {
    events: [
      "65e18444616501042f704ce2",
      "65e186a27054b111009b22ab",
      "65e1871b10e14111471f6b22",
    ],
    status: "true",
    pbaTournament: ["PBA2022"],
    isFeaturedPlayer: false,
    _id: "65e18dd4d8268b11de742a12",
    email: "your.email+fakedata69618@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T08:12:04.971Z",
    firstName: "Ericka",
    lastName: "Hintz",
    membershipNr: "382",
    pbaNr: "Ratione quasi assumenda magnam.",
    updatedAt: "2024-03-01T08:12:04.971Z",
  },
  {
    events: [
      {
        format: [],
        _id: "65e1863e331dc8109e53295a",
        description: "Mollitia nam quaerat.",
        location: "Est porro nam aspernatur quos unde dolores id.",
        endDate: "2017-12-31T22:00:00.000Z",
        startDate: "2004-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Direct Response Director",
        createdAt: "2024-03-01T07:39:42.895Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e187ca10e14111471f6b2c",
        description: "Iusto nostrum maiores unde non.",
        location:
          "Quam beatae labore occaecati molestias nostrum labore nobis quia.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        subCat: "novice",
        gender: "female",
        region: "club",
        category: "doubles",
        title: "Direct Web Facilitator",
        createdAt: "2024-03-01T07:46:18.763Z",
        __v: 0,
      },
      {
        format: [],
        _id: "65e186a27054b111009b22ab",
        description:
          "Repellendus adipisci ipsa dolorem cumque itaque numquam consequatur temporibus.",
        location:
          "Placeat ullam excepturi explicabo itaque veritatis numquam natus nulla ab.",
        endDate: "2024-01-05T22:00:00.000Z",
        startDate: "2023-12-31T22:00:00.000Z",
        category: "doubles",
        title: "Product Solutions Architect",
        createdAt: "2024-03-01T07:41:22.556Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: [],
    isFeaturedPlayer: false,
    _id: "65e1a11cd8268b11de7435de",
    email: "your.email+fakedata48442@gmail.com",
    __v: 0,
    createdAt: "2024-03-01T09:34:20.338Z",
    firstName: "Rhea",
    lastName: "Zemlak",
    membershipNr: "252",
    pbaNr: "Dolorum adipisci voluptas.",
    updatedAt: "2024-03-01T09:34:20.338Z",
  },
  {
    events: [
      {
        format: [],
        _id: "65e83b6075af61497c2d3e6f",
        description: "Doloribus dicta molestiae.",
        location: "Suscipit fugiat ex.",
        endDate: "2030-12-31T22:00:00.000Z",
        startDate: "2001-12-31T22:00:00.000Z",
        subCat: "handicap",
        gender: "mixed",
        region: "club",
        category: "doubles",
        title: "Chief Quality Developer",
        createdAt: "2024-03-06T09:46:08.355Z",
        __v: 0,
      },
    ],
    status: "true",
    pbaTournament: [],
    isFeaturedPlayer: false,
    _id: "65e56675b4ce07711c5fbe6e",
    email: "www@www.com",
    password: "$2b$10$qQl.OLO/i4UHmLg7ryweROXXZuzLlqRAw9V3yWxTyjKn3iW9j5M4S",
    firstName: "www",
    lastName: "www",
    createdAt: "2024-03-04T06:13:09.405Z",
    updatedAt: "2024-03-06T09:47:04.456Z",
    __v: 0,
    membershipNr: "o",
    pbaNr: "o",
  },
];

export const addEventAsync = createAsyncThunk(
  "event/addEvent",
  async (credentials, thunkAPI) => {
    try {
      const event = await eventService.addEvent(credentials);
      return event;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getEventsAsync = createAsyncThunk(
  "event/getEvents",
  async (_, thunkAPI) => {
    try {
      const events = await eventService.getEvents();
      return events;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getEventUsersAsync = createAsyncThunk(
  "event/getEventUsers",
  async (id, thunkAPI) => {
    try {
      const eventUsers = dummyUser;
      return eventUsers;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addTeam = createAsyncThunk(
  "event/addTeam",
  async (teamName, thunkAPI) => {
    try {
      const newTeam = await eventService.addTeam(teamName);
      return newTeam;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addPlayerToTeam = createAsyncThunk(
  "event/addPlayerToTeam",
  async ({ teamId, playerIndices }, thunkAPI) => {
    try {
      const updatedTeam = await eventService.addPlayerToTeam(
        teamId,
        playerIndices
      );
      return { teamId, updatedTeam };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removePlayerFromTeam = createAsyncThunk(
  "event/removePlayerFromTeam",
  async ({ teamId, playerIndex }, thunkAPI) => {
    try {
      const updatedTeam = await eventService.removePlayerFromTeam(
        teamId,
        playerIndex
      );
      return { teamId, updatedTeam };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const switchTeamMembers = createAsyncThunk(
  "event/switchTeamMembers",
  async (playerIndices, thunkAPI) => {
    try {
      const switchedTeams = await eventService.switchTeamMembers(playerIndices);
      return switchedTeams;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEventAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addEventAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.events.push(action.payload);
      })
      .addCase(addEventAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEventsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(getEventsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEventUsersAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.eventUsers = action.payload;
      })
      .addCase(getEventUsersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.teams.push(action.payload);
      })
      .addCase(addTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addPlayerToTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPlayerToTeam.fulfilled, (state, action) => {
        state.loading = false;
        const { teamId, updatedTeam } = action.payload;
        state.teams[teamId] = updatedTeam;
      })
      .addCase(addPlayerToTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removePlayerFromTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removePlayerFromTeam.fulfilled, (state, action) => {
        state.loading = false;
        const { teamId, updatedTeam } = action.payload;
        state.teams[teamId] = updatedTeam;
      })
      .addCase(removePlayerFromTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(switchTeamMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(switchTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.teams = action.payload;
      })
      .addCase(switchTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default eventSlice.reducer;
